import styled, { keyframes } from 'styled-components'

import { theme } from 'styles/theme'

const { radii, colors, space, zIndices } = theme

const fadeIn = keyframes`
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Background = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: ${zIndices.overlay};
  inset: 0;
  background-color: ${colors['neutral-background-contrast-medium']}CC;
  animation: ${fadeIn} 0.4s ease;
`

const slideDown = keyframes`
  0% {
    opacity: 0;
  }
  1% {
    transform: translate(0, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: ${zIndices.max};
  width: 100%;
  max-width: 22rem;
  height: auto;
  padding: ${space['spacing-sm']};
  gap: ${space['spacing-sm']};
  border-radius: ${radii['corner-radius-sm']};
  background-color: ${colors['neutral-background-default']};
  animation: ${slideDown} 0.6s ease;

  > p {
    text-align: center;
  }

  > button:last-of-type {
    justify-content: center;
    width: 100%;
  }
`

export const CloseModal = styled.button`
  all: unset;
  position: absolute;
  top: ${space['spacing-xs']};
  right: ${space['spacing-xs']};
  background-color: transparent;
  z-index: ${zIndices[1]};
  cursor: pointer;
`
