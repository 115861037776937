import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'

import { Button, Icon, Text } from '@cash-me/react-components'
import { useRouter } from 'next/router'

import useClickOutside from 'hooks/useClickOutside'
import { useScrollLock } from 'hooks/useScrollLock'
import { buildSimulatorUrl } from 'src/utils/buildSimulatorUrl'
import { pushDataLayer } from 'utils/push-data-layer'

import * as S from './styles'

export const ExitPopup: FC = memo(() => {
  const router = useRouter()
  const bgModalRef = useRef<HTMLDivElement>(null)
  const [lockScroll, unlockScroll] = useScrollLock()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleButtonClick = useCallback(() => {
    pushDataLayer({
      category: 'Clique',
      action: 'Botões',
      label: `Exit Popup - ${router.pathname} - Simular agora gratuitamente`
    })

    location.href = buildSimulatorUrl(router.pathname, { medium: 'ExitPopup-SimularAgoraGratuitamente' })
  }, [router.pathname])

  const handleKeyUp = useCallback(
    e => {
      if (e.keyCode === 27) {
        e.preventDefault()
        window.removeEventListener('keyup', handleKeyUp, false)
        unlockScroll()
        setIsOpen(false)
      }
    },
    [unlockScroll]
  )

  const handleClose = useCallback(() => {
    unlockScroll()
    setIsOpen(false)
  }, [unlockScroll])

  const exitEvent = useCallback(
    e => {
      if (!e.toElement && !e.relatedTarget) {
        setIsOpen(true)
        lockScroll()

        document.removeEventListener('mouseout', exitEvent)

        setTimeout(
          () => {
            document.addEventListener('mouseout', exitEvent)
          },
          5 * 60 * 1000
        )
      }
    },
    [lockScroll]
  )

  useClickOutside(bgModalRef, handleClose)

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('mouseout', exitEvent)
    }, 6 * 1000)

    window.addEventListener('keyup', handleKeyUp, false)

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false)
    }
  }, [exitEvent, handleKeyUp])

  return (
    <S.Background open={isOpen}>
      <S.Content ref={bgModalRef}>
        <S.CloseModal onClick={handleClose} aria-label="Close">
          <Icon name="close" size={16} color="var(--colors-neutral-icon-default)" />
        </S.CloseModal>

        <Icon name="clock" size={42} color="var(--colors-blue-icon-default)" />
        <Text as="h2" size="heading5" weight="medium">
          Ei, espere. Não vá embora!
        </Text>

        <Text size="body2" color="var(--colors-neutral-text-weak)">
          Está precisando de dinheiro?
          <br />
          Faça uma simulação de empréstimo sem compromisso. É rápido e fácil
        </Text>

        <Button size="lg" variants="text-only" onClick={handleButtonClick}>
          Simular agora gratuitamente
        </Button>
      </S.Content>
    </S.Background>
  )
})

ExitPopup.displayName = 'ExitPopup'
