import { Text, Link } from '@cash-me/react-components'

import { pushDataLayer } from 'utils/push-data-layer'

const handleClickContact = (section, questionOrder) => {
  const question = questionList[section - 1].questions[questionOrder - 1]

  pushDataLayer({
    category: 'Clique',
    action: 'Botões',
    label: `Accordion Pergunta: ${question.title} - Fale Conosco`
  })

  location.href = 'http://www.cashme.com.br/fale-conosco'
}

const handleClickPrivacy = (section, questionOrder) => {
  const question = questionList[section - 1].questions[questionOrder - 1]

  pushDataLayer({
    category: 'Clique',
    action: 'Botões',
    label: `Accordion Pergunta: ${question.title} - Aviso de Privacidade`
  })

  location.href = 'http://www.cashme.com.br/aviso-de-privacidade/'
}

export const questionList = [
  {
    id: 'section01',
    title: 'Sobre a CashMe',
    questions: [
      {
        id: 'section01-01',
        title: 'Quem é a CashMe?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              A CashMe é uma fintech, ou seja, uma empresa de tecnologia do ramo financeiro.
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Nós nascemos com a missão de combater a burocratização do processo de aquisição de crédito com a ajuda da
              tecnologia.
            </Text>
          </>
        )
      },
      {
        id: 'section01-02',
        title: 'Vocês atendem a todo o Brasil?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            A CashMe atende à grande parte do território nacional. Para saber se o seu imóvel está dentro da nossa área
            de atuação, nos informe em qual cidade ele está localizado no momento da simulação.
          </Text>
        )
      },
      {
        id: 'section01-03',
        title: 'Onde vocês estão localizados?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O escritório da CashMe está situado em São Paulo (SP), no bairro Vila Olímpia.
          </Text>
        )
      },
      {
        id: 'section01-04',
        title: 'Qual é o número de telefone da CashMe?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            No momento, A CashMe está se preparando para abrir um canal direto por telefone para o público. Enquanto
            isso, para entrar em contato com a nossa equipe, envie uma mensagem pelo{' '}
            <Link onClick={() => handleClickContact(1, 4)}>
              <Text as="span" size="body2" color="var(--colors-blue-text-default)">
                Fale Conosco
              </Text>
            </Link>
            . Você vai receber uma resposta em até 24h úteis.
          </Text>
        )
      },
      {
        id: 'section01-05',
        title: 'Para que utilizam meus dados pessoais?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            A CashMe utiliza seus dados pessoais apenas para realizar as finalidades informadas a você. Para maiores informações sobre como tratamos seus dados, acesse nosso{' '}
            <Link onClick={() => handleClickPrivacy(1, 5)}>
              <Text as="span" size="body2" color="var(--colors-blue-text-default)">
                Aviso de Privacidade
              </Text>
            </Link>
          </Text>
        )
      },
      {
        id: 'section01-06',
        title: 'Como faço para evitar golpes financeiros em nome da CashMe?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Fique atento sempre aos seguintes detalhes:
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              1. Na CashMe não solicitamos nenhum tipo de depósito ou pagamento antecipado para aprovação da operação de empréstimo.
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              2. Verifique sempre os endereços de e-mail que recebe, aqui na CashMe só enviamos com o @cashme.com.br. Por razões de segurança, não enviamos e-mails com extensões (@gmail, @yahoo, @hotmail, etc.).
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              3. Nunca compartilhe senhas, códigos de segurança ou selfies, essa é uma informação valiosa. 
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              4. Quando tiver qualquer dúvida, recomendamos que entre em contato direto com nosso e-mail fale.conosco@cashme.com.br.
            </Text>
          </>
        )
      }
    ]
  },
  {
    id: 'section02',
    title: 'Sobre o Empréstimo',
    questions: [
      {
        id: 'section02-01',
        title: 'O que é alienação fiduciária?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              A alienação fiduciária é uma modalidade de empréstimo que acontece a partir de um acordo firmado entre o
              credor e o cliente, com o objetivo de colocar o imóvel como garantia de crédito. O credor vincula seu nome
              à documentação legal do bem, porém, o contratante mantém o direito de posse direta, podendo usufruir do
              imóvel como moradia.
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Em nosso caso, o fiduciário é a CashMe e o contratante, o cliente. Após a dívida ser totalmente quitada, o
              imóvel colocado como garantia volta a ser de propriedade total do contratante.
            </Text>
          </>
        )
      },
      {
        id: 'section02-02',
        title: 'Qual é a taxa de juros?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Na CashMe, oferecemos opções pré e pós-fixadas. Para saber mais, entre em contato com a gente{' '}
            <Link onClick={() => handleClickContact(2, 2)} size="default">
              <Text as="span" size="body2" color="var(--colors-blue-text-default)">
                aqui
              </Text>
            </Link>
            .
          </Text>
        )
      },
      {
        id: 'section02-03',
        title: 'Como funciona o empréstimo com garantia de imóvel?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              O empréstimo com garantia de imóvel é uma linha de crédito destinada tanto à pessoa física quanto à
              jurídica. Como o imóvel é usado como garantia, as taxas de juros são bem mais baixas se comparadas às
              outras modalidades de crédito.
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Além disso, o prazo de pagamento é estendido (até 240 meses) e o valor de empréstimo também é maior (a
              partir de R$ 50 mil).
            </Text>
          </>
        )
      },
      {
        id: 'section02-04',
        title: 'O empréstimo com garantia de imóvel é para mim?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O empréstimo com garantia de imóvel é ideal para quem, por exemplo, deseja captar recursos para
            investimentos, organizar dívidas ou reformar um imóvel. Com a CashMe, o seu projeto pode ter qualquer
            tamanho e você pode usar o crédito para a finalidade que escolher!
          </Text>
        )
      },
      {
        id: 'section02-05',
        title: 'Vocês aceitam terreno como garantia?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Sim, mediante análise.
          </Text>
        )
      },
      {
        id: 'section02-06',
        title: 'Qual é o valor máximo de empréstimo que eu posso solicitar?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Você pode solicitar até 60% do valor de avaliação do seu imóvel. Na CashMe, o limite máximo é de R$ 15
            milhões.
          </Text>
        )
      },
      {
        id: 'section02-07',
        title: 'Qual é o valor mínimo de empréstimo que eu posso solicitar?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O valor mínimo de empréstimo que você pode solicitar com a CashMe é de R$ 50 mil.
          </Text>
        )
      },
      {
        id: 'section02-08',
        title: 'Vocês aceitam carro ou moto como garantia?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Não. Apenas imóvel.
          </Text>
        )
      },
      {
        id: 'section02-09',
        title: 'Consigo solicitar um empréstimo em nome de Pessoa Jurídica ou com garantia em nome de Pessoa Jurídica?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Sim. Na CashMe, trabalhamos tanto com operações quanto com garantias em nome de Pessoa Jurídica.
          </Text>
        )
      },
      {
        id: 'section02-10',
        title: 'Consigo solicitar um empréstimo estando negativado?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Sim. Na CashMe você pode realizar uma simulação de empréstimo e realizamos uma análise de 
            crédito e do seu perfil.
          </Text>
        )
      }
    ]
  },
  {
    id: 'section03',
    title: 'Imóvel como Garantia',
    questions: [
      {
        id: 'section03-01',
        title: 'Como o imóvel é colocado em garantia?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O imóvel é colocado como garantia por meio de uma alienação fiduciária.{' '}
          </Text>
        )
      },
      {
        id: 'section03-02',
        title: 'Posso deixar um imóvel financiado como garantia?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Sim. No caso, o valor que você solicitará como empréstimo, somado ao saldo devedor de seu financiamento, não
            poderá ultrapassar 60% do valor de avaliação do seu imóvel.
          </Text>
        )
      },
      {
        id: 'section03-03',
        title: 'Meu imóvel não tem inventário. Posso fazer empréstimo?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Infelizmente não. A documentação do imóvel deve estar regularizada para iniciarmos a sua análise.{' '}
          </Text>
        )
      },
      {
        id: 'section03-04',
        title: 'Tenho dívidas de IPTU. Consigo fazer o empréstimo?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Neste caso, realizamos uma análise de crédito e, se tivermos a aprovação, condicionamos a quitação do IPTU
            por dentro da operação. É importante apenas que você nos informe o valor da dívida, para que possamos
            considerá-la em sua análise.
          </Text>
        )
      },
      {
        id: 'section03-05',
        title: 'Tenho dívidas de condomínio. Consigo fazer o empréstimo?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Neste caso, realizamos uma análise de crédito e, se tivermos a aprovação, condicionamos a quitação do
            condomínio por dentro da operação. É importante apenas que você nos informe o valor da dívida, para que
            possamos considerá-la em sua análise.
          </Text>
        )
      }
    ]
  },
  {
    id: 'section04',
    title: 'Processo de contração',
    questions: [
      {
        id: 'section04-01',
        title: 'Em quanto tempo o dinheiro é depositado na conta?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Para que o dinheiro seja depositado na sua conta, realizamos um processo de análise que precisa ser
            aprovado. A partir da aprovação do seu crédito, fazemos o registro do contrato em cartório e o valor
            acordado é liberado. Dependendo da análise, esse processo ocorre a partir de 7 dias.
          </Text>
        )
      },
      {
        id: 'section04-02',
        title: 'Como é feito o pagamento do empréstimo?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O pagamento do empréstimo pode ser feito por boleto ou DDA.
          </Text>
        )
      },
      {
        id: 'section04-03',
        title: 'De quais documentos vocês precisam?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Precisamos do IPTU do ano vigente, contendo a matrícula completa e a metragem do imóvel.
          </Text>
        )
      },
      {
        id: 'section04-04',
        title: 'Com quantas pessoas posso fazer composição de renda?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            A composição de renda pode ser feita por até 4 pessoas.
          </Text>
        )
      },
      {
        id: 'section04-05',
        title: 'Quanto vocês cobram pelo serviço?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            A CashMe não cobra nenhum valor de serviço antecipado. As tarifas estão todas embutidas no valor da sua
            parcela e serão cobradas apenas após a assinatura do contrato.
          </Text>
        )
      }
    ]
  },
  {
    id: 'section05',
    title: 'Sobre Pagamento',
    questions: [
      {
        id: 'section05-01',
        title: 'Como faço para saber o valor da parcela?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Você pode consultar o valor da parcela do seu contrato via nossos canais de suporte:
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Whatsapp: (11) 4040-2550;
              <br />
              E-mail: suporte.pagamento@cashme.com.br
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Lembrando que nosso horário de atendimento é de segunda a sexta-feira, das 9h às 17h.
            </Text>
          </>
        )
      },
      {
        id: 'section05-02',
        title: 'Posso antecipar as parcelas?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Sim. Basta você entrar em contato com a gente via nossos canais de suporte:
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Whatsapp: (11) 4040-2550;
              <br />
              E-mail: suporte.pagamento@cashme.com.br
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Lembrando que nosso horário de atendimento é de segunda a sexta-feira, das 9h às 17h.
            </Text>
          </>
        )
      },
      {
        id: 'section05-03',
        title: 'Posso pagar com o FGTS?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O FGTS é liberado para a compra do bem. Por isso, a sua utilização não é permitida para o empréstimo com
            garantia de imóvel.
          </Text>
        )
      },
      {
        id: 'section05-04',
        title: 'Como funciona a carência adicional na CashMe?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Os clientes pessoa jurídica da CashMe adquirem o direito de solicitar a carência adicional para seus
              pagamentos, ajudando a aliviar as contas e se restabelecer financeiramente.
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Para que o pedido de carência seja aceito, ele deve passar por análise interna e validação de todas as
              obrigações contratuais.
            </Text>
          </>
        )
      }
    ]
  }
]
