import { FC, memo, useMemo } from 'react'

import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import { ExitPopup } from 'components/exit-popup'
import { QuickHelp } from 'components/quick-help'

import { Navbar } from '../navbar'

import { LayoutProps } from './layout.model'
import * as S from './styles'

const Footer = dynamic(() => import('../footer'), { ssr: false })
const Quicklinks = dynamic(() => import('../quicklinks'), { ssr: false })
const Warning = dynamic(() => import('../warning'), { ssr: false })

export const Layout: FC<LayoutProps> = memo(({ hasCTA = false, footerLegalText, children }) => {
  const { pathname } = useRouter()
  const disableList = useMemo(
    () => [
      '/condo',
      '/condo/indica',
      '/consorcio',
      '/emprestimo-com-garantia-de-imovel',
      '/emprestimo-online',
      '/emprestimo-para-reforma',
      '/emprestimo-para-empresa',
      '/fale-conosco',
      '/financiamento-energia-solar',
      '/financiamento-imobiliario',
      '/home-equity',
      '/newsletter',
      '/portabilidade-de-dividas',
      '/programa-de-indicacao',
      '/programa-de-integridade',
      '/quem-somos',
      '/simulacao-de-emprestimo',
      '/relacoes-com-investidores',
      '/sou-cliente',
      '/seja-um-parceiro'
    ],
    []
  )
  const exitDisableList = useMemo(
    () => [
      '/programa-de-indicacao', 
      '/trabalhe-conosco', 
      '/fale-conosco', 
      '/sou-cliente', 
      '/relacoes-com-investidores'
    ],[]
  )

  return (
    <S.Container {...{ hascta: hasCTA }}>
      <S.TopHeader>
        <Navbar />
        <Warning />
        {!disableList.includes(pathname) && <QuickHelp />}
      </S.TopHeader>
      {children}
      <Quicklinks />
      <Footer additionalLegalText={footerLegalText} />
      {!exitDisableList.includes(pathname) && <ExitPopup />}
    </S.Container>
  )
})

Layout.displayName = 'Layout'
